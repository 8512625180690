function HeroSection() {
  return (
    <div className="bg">
      <div className="login-page-badge">
        <h1
          class="text-6xl center"
          style={{
            color: "#424242",
            fontWeight: "600",
            lineHeight: "76px",
            opacity: "1",
            textShadow:
              "-1px -1px 0 #F5F5F5, 1px -1px 0 #F5F5F5, -1px 1px 0 #F5F5F5, 1px 1px 0 #F5F5F5",
          }}
        >
          David Card
        </h1>
        <h3
          className="text-3xl center"
          style={{
            marginTop: "1rem",
            fontFamily: "Prompt",
            fontWeight: "400",
            color: "#FFFFFF",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.45)",
            opacity: "1",
          }}
        >
          นามบัตรดิจิตอลอัจฉริยะ
        </h3>
      </div>
    </div>
  );
}

export default HeroSection;
