//ProfileForm shows a form for a user to add input
import _ from "lodash";
import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";

import history from "../../history";
import ProfileField from "./ProfileField";
import formFields from "./formFields";
import "./ProfileForm.css";
import Header from ".././Header";

class ProfileForm extends Component {
  constructor() {
    super();
    this.state = {
      file: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick() {
    this.setState((preState) => {
      return {
        file: 0,
      };
    });
  }

  validateImageSize = (imageFile) => {
    if (imageFile && imageFile.size) {
      const imageFileKb = imageFile.size / 1024;
      const maxWeight = 1000;
      if (imageFileKb > maxWeight) {
        return `Image size must be less or equal to ${maxWeight} kb`;
      }
    }
  };

  validateImageFormat = (imageFile) => {
    if (imageFile) {
      const mimeType = "image/jpeg, image/png";
      if (!mimeType.includes(imageFile.type)) {
        return `Image type must be ${mimeType}`;
      }
    }
  };

  renderFields() {
    return _.map(formFields, ({ label, name, type }) => {
      return (
        <Field
          key={name}
          component={ProfileField}
          type={type}
          label={label}
          name={name}
        />
      );
    });
  }

  renderInput = ({ input, type, meta, label }) => {
    const { mime } = this.props;

    return (
      <div>
        <label style={{ fontSize: "14px", color: "#0094FF" }}>{label}</label>
        <br></br>
        <input
          name={input.name}
          type={type}
          accept={mime}
          onChange={(event) => this.handleChange(event, input)}
        />
        <div className="red-text" style={{ marginBottom: "20px" }}>
          {meta && meta.invalid && meta.error}
        </div>
        <img src={this.state.file} style={{ height: "150px" }} />
      </div>
    );
  };

  handleChange = (event, input) => {
    event.preventDefault();

    let imageFile = event.target.files[0];
    this.setState((pre) => {
      return {
        file: URL.createObjectURL(imageFile),
      };
    });
    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = () => {
        imageFile.width = imageObject.naturalWidth;
        imageFile.height = imageObject.naturalHeight;
        input.onChange(imageFile);
        URL.revokeObjectURL(imageFile);
      };
      imageObject.src = localImageUrl;
    }
  };

  render() {
    return (
      <div className="bg-form">
        <Header />
        <div className="input-form-wraper">
          <div className="input-form-header">
            <h1 class="text-5xl center">E-NAMECARD</h1>
          </div>

          <div className="input-form-inner">
            <form
              class="input-field"
              onSubmit={this.props.handleSubmit(this.props.onProfileSubmit)}
            >
              {this.renderFields()}

              <Field
                key="logo"
                label="ไฟล์โลโก้บริษัท"
                name="logo"
                type="file"
                validate={[this.validateImageSize, this.validateImageFormat]}
                component={this.renderInput}
              />
              <br />
              <Field
                key="avatar"
                label="ภาพโปรไฟล์ส่วนตัว"
                name="avatar"
                type="file"
                validate={[this.validateImageSize, this.validateImageFormat]}
                component={this.renderInput}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <a
                  href="/profiles"
                  className="grey darken-1 btn-flat white-text"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  className="blue btn-flat right white-text"
                >
                  Next
                  <i className="material-icons right">done</i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// function validate(values) {
//   const errors = null; //kan, not validate

// if (!values.email1) {
//   errors.email1 = 'Required'
// } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email1)) {
//   errors.email1 = 'Invalid email address'
// }

// _.each(formFields, ({ name }) => {
//   if (!values[name]) {
//     errors[name] = 'กรุณากรอกข้อมูล'
//   }
// })

//   return errors;
// }

export default reduxForm({
  form: "profileForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(ProfileForm);
