import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import formFields from "./formFields";
import * as actions from "../../actions";
import "./ProfileFormReview.css";

const ProfileFormReview = ({ onCancel, formValues, submitProfile }) => {
  const reviewFileds = _.map(formFields, ({ name, label }) => {
    return (
      <div key={name}>
        <label>{label}</label>
        <div>{formValues[name]}</div>
      </div>
    );
  });

  return (
    <div className="main-container-review">
      <div>
        <div className="input-form-wraper">
          <div className="input-form-header">
            <h1 className="review-title">Confirm your entries</h1>
          </div>

          <div className="review-card">{reviewFileds}</div>
        </div>

        <div className="button-div">
          <button
            className="grey darken-1 btn-flat white-text"
            onClick={onCancel}
          >
            Back
          </button>
          <button
            onClick={() => {
              const formData = new FormData();
              formData.append(
                "profile_title",
                formValues.profile_title === undefined
                  ? "-"
                  : formValues.profile_title
              );
              formData.append(
                "input_name",
                formValues.input_name === undefined
                  ? "-"
                  : formValues.input_name
              );
              formData.append(
                "input_name_en",
                formValues.input_name_en === undefined
                  ? "-"
                  : formValues.input_name_en
              );
              formData.append(
                "company_name",
                formValues.company_name === undefined
                  ? "-"
                  : formValues.company_name
              );
              formData.append(
                "company_name_en",
                formValues.company_name_en === undefined
                  ? "-"
                  : formValues.company_name_en
              );
              formData.append(
                "title",
                formValues.title === undefined ? "-" : formValues.title
              );
              formData.append(
                "title_en",
                formValues.title_en === undefined ? "-" : formValues.title_en
              );
              formData.append(
                "address",
                formValues.address === undefined ? "-" : formValues.address
              );
              formData.append(
                "address_en",
                formValues.address_en === undefined
                  ? "-"
                  : formValues.address_en
              );
              formData.append(
                "location",
                formValues.location === undefined ? "-" : formValues.location
              );
              formData.append(
                "email1",
                formValues.email1 === undefined ? "-" : formValues.email1
              );
              formData.append(
                "email2",
                formValues.email2 === undefined ? "-" : formValues.email2
              );
              formData.append(
                "website",
                formValues.website === undefined ? "-" : formValues.website
              );
              formData.append(
                "line",
                formValues.line === undefined ? "-" : formValues.line
              );
              formData.append(
                "tel1",
                formValues.tel1 === undefined ? "-" : formValues.tel1
              );
              formData.append(
                "tel2",
                formValues.tel2 === undefined ? "-" : formValues.tel2
              );
              formData.append(
                "tel3",
                formValues.tel3 === undefined ? "-" : formValues.tel3
              );
              formData.append(
                "facebook",
                formValues.facebook === undefined ? "-" : formValues.facebook
              );
              formData.append(
                "instagram",
                formValues.instagram === undefined ? "-" : formValues.instagram
              );
              formData.append(
                "tiktok",
                formValues.tiktok === undefined ? "-" : formValues.tiktok
              );
              formData.append(
                "whatsapp",
                formValues.whatsapp === undefined ? "-" : formValues.whatsapp
              );
              formData.append(
                "linkedIn",
                formValues.linkedIn === undefined ? "-" : formValues.linkedIn
              );
              formData.append(
                "aboutme",
                formValues.aboutme === undefined ? "-" : formValues.aboutme
              );
              formData.append("createdAt", new Date());
              formData.append(
                "logo",
                formValues.logo === undefined ? undefined : formValues.logo
              );
              formData.append(
                "avatar",
                formValues.avatar === undefined ? undefined : formValues.avatar
              );
              submitProfile(formData);
            }}
            className="blue btn-flat right white-text"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  // console.log(state);
  return {
    formValues: state.form.profileForm.values,
  };
}

export default connect(mapStateToProps, actions)(ProfileFormReview);
