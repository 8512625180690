import {
  FETCH_PROFILES,
  DELETE_PROFILE,
  EDIT_PROFILE,
  FETCH_PROFILE,
  ADD_PROFILE,
} from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PROFILES:
      return action.payload || false;
    case FETCH_PROFILE:
      return action.payload || false;
    case ADD_PROFILE:
      return [...state, action.payload];
    case DELETE_PROFILE:
      return state.filter((id) => id._id !== action.payload);
    case EDIT_PROFILE:
      return action.payload || false;
    default:
      return state;
  }
};
