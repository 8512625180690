//Profile Shows ProfileForm and ProfileFormReview
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import ProfileForm from "./ProfileForm";
import ProfileFormReview from "./ProfileFormReview";

class ProfileNew extends Component {
  state = { showFormReview: false };

  renderContent() {
    if (this.state.showFormReview) {
      return (
        <ProfileFormReview
          onCancel={() => this.setState({ showFormReview: false })}
        />
      );
    }

    return (
      <div>
        <ProfileForm
          onProfileSubmit={() => this.setState({ showFormReview: true })}
        />
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default reduxForm({
  form: "profileForm",
})(ProfileNew);
