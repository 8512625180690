import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { fetchProfiles, deleteProfile, editProfile } from "../../actions";
import "./ProfileList.css";
import Header from ".././Header";

const Swal = require("sweetalert2");

class ProfileList extends Component {
  componentDidMount() {
    const user = auth.currentUser;
    this.props.fetchProfiles(user.uid);
  }

  handleClickRemove(id) {
    this.props.deleteProfile(id);
  }

  confirmRemove(id) {
    Swal.fire({
      title: "Delete your profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your Profile has been deleted.", "success");
        this.handleClickRemove(id);
      }
    });
  }

  renderProfiles() {
    if (!this.props.profiles) {
      return <div>Loading..</div>;
    }
    return this.props.profiles.map((profile, index) => {
      return (
        <div className="row">
          <div className="col s12 m6 offset-m3">
            <div
              className="card blue darken-4"
              style={{ borderRadius: "25px" }}
              key={profile._id}
            >
              <div className="card-content">
                <span className="card-title white-text">
                  {profile.profile_title}
                </span>
                <p className="right white-text">
                  Created : {new Date(profile.createdAt).toLocaleDateString()}
                  &nbsp;&nbsp;
                  {new Date(profile.createdAt).toLocaleTimeString()}
                </p>
              </div>

              <div className="card-action">
                <Link
                  to={`/profiles/edit/${profile._id}`}
                  className="blue btn-flat white-text"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  Edit
                </Link>

                <button
                  // onClick={() => this.handleClickRemove(profile._id)}
                  onClick={() => this.confirmRemove(profile._id)}
                  className="btn-remove white darken-1 btn-flat"
                >
                  Remove
                </button>

                <Link
                  to={`/profiles/card/${profile._id}`}
                  target="_blank"
                  className="blue btn-flat white-text right"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="bg-list">
        <Header />
        <div className="profile-card">{this.renderProfiles()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  profiles: state.profiles,
});

export default connect(mapStateToProps, {
  fetchProfiles,
  deleteProfile,
  editProfile,
})(ProfileList);
