import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfile, editProfile, fetchProfiles } from "../../actions";
import ProfileForm from "./ProfileForm";
import ProfileFormEditPreview from "./ProfileFormEditPreview";

class ProfileEdit extends Component {
  state = { showFormEditReview: false };

  componentDidMount() {
    this.props.fetchProfile(this.props.match.params.id);
  }

  renderContent() {
    if (this.state.showFormEditReview) {
      return (
        <ProfileFormEditPreview
          onCancel={() => this.setState({ showFormEditReview: false })}
        />
      );
    }
    const { avatar, logo, ...rest } = this.props.profiles; //remove key from object

    return (
      <div>
        <ProfileForm
          initialValues={rest}
          onProfileSubmit={() => this.setState({ showFormEditReview: true })}
        />
      </div>
    );
  }

  render() {
    if (!this.props.profiles) {
      return <div>Loading...</div>;
    }
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = ({ profiles }, props) => {
  return {
    profiles,
  };
};

export default connect(mapStateToProps, {
  fetchProfile,
  editProfile,
  fetchProfiles,
})(ProfileEdit);
