import history from "../history";
import { firestore, auth, storage } from "../firebase";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import { signOut } from "firebase/auth";
import {
  setDoc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  doc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";

import {
  FETCH_PROFILES,
  FETCH_USER,
  DELETE_PROFILE,
  EDIT_PROFILE,
  FETCH_PROFILE,
  LOGIN,
  LOGOUT,
  ADD_PROFILE,
} from "./types";

export const login = (user) => async (dispatch) => {
  dispatch({ type: LOGIN, user });
};

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const startLogout = () => {
  return () => {
    return signOut(auth);
  };
};

export const fetchUser = () => async (dispatch) => {
  const user = auth.currentUser;
  dispatch({ type: FETCH_USER, payload: user });
};

export const submitProfile = (values) => async (dispatch, getState) => {
  const user = getState().auth;
  const uid = getState().auth.uid;
  const formDataObj = {};
  const docRef = doc(collection(firestore, "users", uid, "profiles"));

  await setDoc(docRef, formDataObj)
    .then(async () => {
      const promises = [];

      values.forEach(async (value, key) => {
        if (key === "logo") {
          if (value) {
            const storageRef = ref(storage, `logos/thumb_${docRef.id}`);
            const uploadTask = uploadBytes(storageRef, value);
            promises.push(uploadTask);
            formDataObj[key] = `thumb_${docRef.id}`;
          }
        } else if (key === "avatar") {
          if (value) {
            const storageRef = ref(storage, `avatars/thumb_${docRef.id}`);
            const uploadTask = uploadBytes(storageRef, value);
            promises.push(uploadTask);
            formDataObj[key] = `thumb_${docRef.id}`;
          }
        } else {
          formDataObj[key] = value;
        }
      });
      Promise.all(promises);
      const addProfile = {
        _id: docRef.id,
        uid: uid,
        ...formDataObj,
      };
      await updateDoc(
        doc(firestore, "users", uid, "profiles", docRef.id),
        addProfile
      );

      dispatch({ type: ADD_PROFILE, payload: addProfile });
    })
    .catch((error) => {
      console.log(error);
    });

  dispatch({ type: FETCH_USER, payload: user });

  history.push("/profiles");
};

export const fetchProfiles = (id) => async (dispatch) => {
  const queryRef = query(
    collection(firestore, "users", id, "profiles"),
    orderBy("createdAt", "desc")
  );
  return await getDocs(queryRef).then((snapshot) => {
    const profiles = [];
    snapshot.forEach(async (childSnapshot) => {
      profiles.push({
        _id: childSnapshot.id,
        ...childSnapshot.data(),
      });
    });
    dispatch({ type: FETCH_PROFILES, payload: profiles });
  });
};

export const fetchProfile = (id) => async (dispatch, getState) => {
  const uid = getState().auth.uid;
  const docRef = doc(firestore, "users", uid, "profiles", id);
  return await getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      // console.log(docSnap.data());

      dispatch({ type: FETCH_PROFILE, payload: docSnap.data() });
    } else {
      console.log("No such document!");
    }
  });
};

export const deleteProfile = (id) => async (dispatch, getState) => {
  const uid = getState().auth.uid;
  // const logoRef = ref(storage, `logos/thumb_${id}`);
  // const avatarRef = ref(storage, `avatars/thumb_${id}`);
  const docRef = doc(firestore, "users", uid, "profiles", id);
  const promises = [];
  const delDoc = await deleteDoc(docRef);

  // const delLogoTask = await deleteObject(logoRef);
  // const delAvatarTask = await deleteObject(avatarRef);
  promises.push(delDoc);

  // promises.push(delLogoTask);
  // promises.push(delAvatarTask);
  Promise.all(promises);
  dispatch({ type: DELETE_PROFILE, payload: id });
  history.push("/profiles");
};

export const editProfile = (id, values) => async (dispatch, getState) => {
  const user = getState().auth;
  const uid = getState().auth.uid;
  const formDataObj = {};
  const promises = [];
  values.forEach((value, key) => {
    if (key === "logo") {
      if (value.name !== undefined) {
        const storageRef = ref(storage, `logos/thumb_${id}`);
        const uploadTask = uploadBytes(storageRef, value);
        promises.push(uploadTask);
        formDataObj[key] = `thumb_${id}`;
      }
    } else if (key === "avatar") {
      if (value.name !== undefined) {
        const storageRef = ref(storage, `avatars/thumb_${id}`);
        const uploadTask = uploadBytes(storageRef, value);
        promises.push(uploadTask);
        formDataObj[key] = `thumb_${id}`;
      }
    } else {
      formDataObj[key] = value;
    }
  });

  const docRef = doc(firestore, "users", uid, "profiles", id);
  return await getDoc(docRef).then(async (docSnap) => {
    if (docSnap.exists()) {
      await updateDoc(
        doc(firestore, "users", uid, "profiles", id),
        formDataObj
      );

      dispatch({ type: EDIT_PROFILE, payload: formDataObj });
    } else {
      console.log("No document!");
    }
    await dispatch(fetchProfiles(user.uid));
    history.push("/profiles");
  });
};
