import "../node_modules/materialize-css/dist/css/materialize.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import history from "./history";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { login, logout, fetchProfiles } from "./actions";
import "./index.css";

// FirebaseUI
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

import App from "./components/App";
import reducers from "./reducers";

var ui = new firebaseui.auth.AuthUI(auth);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

const hasRendered = false;

const renderApp = () => {
  // if (!hasRendered) {
  //   ReactDOM.render(
  //     <Provider store={store}>
  //       <App />
  //     </Provider>,
  //     document.querySelector("#root")
  //   );
  //   hasRendered = true;
  // }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.querySelector("#root")
  );
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch(login(user));
    store.dispatch(fetchProfiles(user.uid)).then(() => {
      renderApp();
    });
  } else {
    store.dispatch(logout());
    renderApp();
  }
});
