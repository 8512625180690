import img1 from "../image/home/intro-davidcard.png";
import img2 from "../image/home/intro-davidcard-city.png";
import img3 from "../image/home/intro-davidcard-card.png";
import img4 from "../image/home/intro-davidcard-onestop.png";

const imgalt1 = "นามบัตรดิจิตอล แตะส่งข้อมูลผ่านมือถือ";
const imgalt2 = "เทคโนโลยี นามบัตร nfc";
const imgalt3 = "เทคโนโลยี นามบัตร nfc david card";
const imgalt4 = "รับทำนามบัตร nfc แบบ One Stop Service";

function IntroSection() {
  return (
    <div className="section-containter">
      {/*  section 1 */}
      <div className="grid-item">
        <img src={img1} alt={imgalt1} />
      </div>
      <div className="grid-item" style={{ flexDirection: "column" }}>
        <div style={{ fontSize: "36px" }}>
          <p>เพียงแค่แตะ</p>
          <p>ก็สามารถส่งผ่านข้อมูลได้</p>
          <br />
          <hr style={{ borderTop: "4px solid #2d46b9", borderRadius: "5px" }} />
        </div>
      </div>

      {/*  section 2 */}
      <div className="grid-item" style={{ background: "#e9edff" }}>
        <img src={img2} alt={imgalt2} />
      </div>
      <div
        className="grid-item"
        style={{ flexDirection: "column", background: "#e9edff" }}
      >
        <div style={{ fontSize: "36px" }}>
          <p>หนึ่งเดียวในประเทศ</p>
          <br />
          <ul className="intro-list-blk">
            <li>1. พัฒนาซอฟแวร์ระบบทั้งหมดเอง</li>
            <li>2. มีความปลอดภัยสูงสุดในการใช้งานและเก็บข้อมูล</li>
            <li>3. มีฟังก์ชั่นการใช้งานที่หลากหลายครบครันที่สุด</li>
          </ul>
          <br />
          <hr style={{ borderTop: "4px solid #2d46b9", borderRadius: "5px" }} />
        </div>
      </div>

      {/*  section 3 */}
      <div
        className="grid-item"
        style={{ flexDirection: "column", background: "#6677C4" }}
      >
        <div
          style={{
            fontSize: "36px",
            width: "90%",
            padding: "10px 0",
            color: "#FFFFFF",
          }}
        >
          <hr
            style={{
              width: "40%",
              borderTop: "4px solid #003257",
              borderRadius: "5px",
            }}
          />
          เทคโนโลยีของเรา
        </div>
        <img src={img3} alt={imgalt3} />
      </div>

      <div
        className="grid-item"
        style={{ flexDirection: "column", background: "#6677C4" }}
      >
        <div>
          <ul className="intro-list" style={{ color: "#FFF" }}>
            <li>1. ส่งผ่านข้อมูลได้รวดเร็ว</li>
            <p>
              เพียงแค่แตะบัตรกับโทรศัพท์มือถือ
              ก็สามารถส่งข้อมูลจากบัตรลงมือถือได้ทันที โดยไม่ต้องลง App ใดๆ
              ใช้ได้ทั้งมือถือระบบ iOS และ Andriod
            </p>
            <br />

            <li>2. บันทึกข้อมูลลงโทรศัพท์ได้ทันที</li>
            <p>
              เบอร์โทร อีเมล ชื่อบริษัท ตำแหน่งออฟฟิศ ไลน์ เว็บไซต์ Facebook
              LinkedIn และอื่นๆ อีกมากมาย
            </p>
            <br />

            <li>3. เพิ่มความน่าเชื่อถือให้ผู้ใช้งาน</li>
            <p>
              บัตรของเราเป็น Digital Business Card
              แห่งยุคที่สามารถสะท้อนภาพลักษณ์ของผู้ใช้งาน ให้ดูดีน่าประทับใจ
              ตั้งแต่แรกพบ
            </p>
            <br />
          </ul>
          <br />
          <hr style={{ borderTop: "4px solid #2d46b9", borderRadius: "5px" }} />
        </div>
      </div>

      {/*  section 4 */}
      <div className="grid-item" style={{ flexDirection: "column" }}>
        <div
          style={{
            fontWeight: "500",
            fontSize: "36px",
            width: "90%",
            padding: "10px 0",
          }}
        >
          <hr
            style={{
              width: "40%",
              borderTop: "4px solid #2d46b9",
              borderRadius: "5px",
            }}
          />
          One Stop Service
        </div>
        <div style={{ width: "90%" }}>
          <ul className="intro-list-blk">
            <li>Custom Design</li>
            <p>
              ลูกค้าสามารถปรับเปลี่ยนหน้าตานามบัตร ใส่โลโก้ของตนเองลงไปได้
              หรือถ้าลูกค้ามีไฟล์หน้านามบัตรเดิมอยู่ก็สามารถส่งมาให้เราพิมพ์บัตรให้ได้เลยทันที
            </p>

            <li>Premium Printing</li>
            <p>
              เรามีเครื่องพิมพ์บัตร PVC คุณภาพสูง ทำให้ได้บัตรที่มีสีสันสวยงาม
              กันน้ำ ทนทาน พร้อมให้บริการได้ทันที
            </p>
          </ul>
          <br />
          <hr style={{ borderTop: "4px solid #2d46b9", borderRadius: "5px" }} />
        </div>
      </div>

      <div className="grid-item" style={{ flexDirection: "column" }}>
        <img src={img4} alt={imgalt4} />
      </div>
    </div>
  );
}

export default IntroSection;
