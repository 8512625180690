import { FETCH_LOGO, FETCH_AVATAR, GET_CARD } from "../actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case FETCH_LOGO:
      return action.payload || false;
    case FETCH_AVATAR:
      return action.payload || false;
    case GET_CARD:
      return action.payload || false;
    default:
      return state;
  }
}
