import img1 from "../image/home/usecase1.png";
import img2 from "../image/home/usecase2.png";
import img3 from "../image/home/usecase3.png";

const imgalt1 = "นามบัตร nfc เพื่อใช้งานส่วนตัว";
const imgalt2 = "นามบัตร nfc เพื่อใช้งานในองค์กร";
const imgalt3 = "มอบนามบัตร nfc เป็นของขวัญ";

function UseCaseSection() {
  return (
    <div className="section-containter-usecase">
      {/*  section 1 */}
      <div
        className="grid-item"
        style={{ flexDirection: "column", padding: "0px", margin: "0px" }}
      >
        <img src={img1} alt={imgalt1} className="img-round" />
        <div className="usecase-title">
          <h2>ใช้งานส่วนตัว</h2>
        </div>
      </div>
      <div
        className="grid-item"
        style={{ flexDirection: "column", padding: "0px", margin: "0px" }}
      >
        <img src={img2} alt={imgalt2} className="img-round" />
        <div className="usecase-title">
          <h2>ทำให้ทีมงานในองค์กร</h2>
        </div>
      </div>
      <div
        className="grid-item"
        style={{ flexDirection: "column", padding: "0px", margin: "0px" }}
      >
        <img src={img3} alt={imgalt3} className="img-round" />
        <div className="usecase-title">
          <h2>มอบเป็นของขวัญ</h2>
        </div>
      </div>
    </div>
  );
}

export default UseCaseSection;
