//ProfileField contains logig to render a single
//label and text input
import React from "react";

export default ({ input, label, type, meta: { touched, error } }) => {
  return (
    <div>
      <label style={{ fontSize: "14px", color: "#0094FF" }}>{label}</label>
      <input
        {...input}
        autoComplete="off"
        type={type}
        style={{ background: "#F7F7F7", borderRadius: "5px" }}
      />
      <div className="red-text" style={{ marginBottom: "20px" }}>
        {touched && error}
      </div>
    </div>
  );
};
