import React from "react";
import "./Landing.css";
import Header from "./Header";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import IntroSection from "./IntroSection";
import UseCaseSection from "./UseCaseSection";
import LeadTime from "./LeadTime";
import CardDesign from "./CardDesign";

const Landing = () => {
  return (
    <div className="main_container">
      <Header />
      <HeroSection />
      <IntroSection />
      <UseCaseSection />
      <LeadTime />
      <CardDesign />
      <Footer />
    </div>
  );
};

export default Landing;
