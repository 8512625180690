import img1 from "../image/home/intro-davidcard-meeting.png"
const imgalt1 = "David card นามบัตร nfc ได้บัตรเร็ว ราคาถูก"

function LeadTime (){

    return(
    <div className="section-containter">

        {/*  section 1 */}
        <div className="grid-item">
            <img src={img1} alt={imgalt1} />
        </div>
        <div className="grid-item" style={{flexDirection:"column"}}>

            <div style={{padding:"10%"}}>
            <hr style={{width:"40%",borderTop:"4px solid #2d46b9" , borderRadius:"5px"}}/>
            <br />
            <p style={{fontSize:"36px"}}>ระยะเวลาในการทำบัตร ไม่เกิน 5 วันทำการ*</p>
            <br />
            <b>ยินดีต้อนรับลูกค้าแบบรายบุคคลและองค์กร</b>
            <ul>
                <li>ราคาบัตร Standard Design ใบละ 699 บาท</li>
                <li>ราคาบัตร Custom Design ใบละ 899 บาท</li>
                <br />
                <li>***สำหรับการสั่งซื้อนามบัตรองค์กรจำนวน 10 ใบขึ้นไป ติดต่อเพื่อขอราคาพิเศษได้ค่ะ</li>
                <br /><br />
                <li>ความรวดเร็วในการทำนามบัตรจะขึ้นอยู่กับการประสานงานเรื่องข้อมูลกับคุณลูกค้า</li>

            </ul>
            </div>
        </div>
    </div>
    
    )}
    
    export default LeadTime