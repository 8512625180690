export default [
  { label: "E-NAMECARD NICK NAME", name: "profile_title", type: "text" },
  { label: "ชื่อ - นามสกุล (ภาษาไทย)", name: "input_name", type: "text" },
  { label: "ชื่อ - นามสกุล (English)", name: "input_name_en", type: "text" },
  { label: "ชื่อบริษัท/องค์กร (ภาษาไทย)", name: "company_name", type: "text" },
  {
    label: "Company Name (English)",
    name: "company_name_en",
    type: "text",
  },
  { label: "Title (ภาษาไทย)", name: "title", type: "text" },
  { label: "Title (English)", name: "title_en", type: "text" },
  { label: "AboutMe", name: "aboutme", type: "text" },
  { label: "Address (ภาษาไทย)", name: "address", type: "text" },
  { label: "Address (English)", name: "address_en", type: "text" },
  { label: "Link Address (Google Map Link)", name: "location", type: "text" },
  { label: "Telephone (0991234567)", name: "tel1", type: "text" },
  { label: "Telephone (Secondary)", name: "tel2", type: "text" },
  { label: "Telephone (Teriary)", name: "tel3", type: "text" },
  { label: "Email (Primary)", name: "email1", type: "text" },
  { label: "Email (Secondary)", name: "email2", type: "text" },
  {
    label: "Company Website (https://www.davidcard.co)",
    name: "website",
    type: "text",
  },
  { label: "Line ID (id : davidline)", name: "line", type: "text" },
  { label: "Whatsapp (id : 0991234567)", name: "whatsapp", type: "text" },
  {
    label: "Facebook (https://www.fb.com/davidcard)",
    name: "facebook",
    type: "text",
  },
  {
    label: "Instagram (id : davidinstragram)",
    name: "instagram",
    type: "text",
  },
  { label: "TikTok (id : 40d9184)", name: "tiktok", type: "text" },
  { label: "LinkedIn", name: "linkedIn", type: "text" },
];
