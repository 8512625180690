import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { startLogout } from "../actions";

class Header extends Component {
  renderContent() {
    // console.log(this.props.auth);
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return (
          <li>
            <Link
              className="waves-effect waves-light btn-large blue lighten-2 "
              style={{
                borderRadius: "5px",
                border: "1px solid white",
              }}
              to="/login"
            >
              Log in
            </Link>
          </li>
        );
      default:
        return (
          <li>
            <Link
              className="waves-effect waves-light btn-large blue lighten-2"
              style={{
                borderRadius: "5px",
                border: "1px solid white",
              }}
              to="#"
              onClick={this.props.startLogout}
            >
              Log out
            </Link>
          </li>
        );
    }
  }

  render() {
    return (
      <nav>
        <div className="blue nav-wrapper">
          <Link
            to={this.props.auth ? "/profiles" : "/"}
            className="left brand-logo"
            style={{ fontWeight: "700", paddingLeft: "20px", fontSize: "24px" }}
          >
            DAVID CARD
          </Link>
          <ul className="right">
            <li>{this.renderContent()}</li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
});

const mapStateToProps = (state, props) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
