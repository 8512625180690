import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { connect, Provider } from "react-redux";
import * as actions from "../actions";

import Landing from "./Landing";
import Dashboard from "./Dashboard";
import ProfileNew from "./profiles/ProfileNew";
import ProfileEdit from "./profiles/ProfileEdit";
import history from "../history";
import ProfileCard from "./profiles/ProfileCard";
import Login from "./Login";
import { ChakraProvider } from "@chakra-ui/react";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <div>
        <ChakraProvider>
          <Router history={history}>
            <div>
              <PublicRoute exact path="/" component={Landing} />
              <PublicRoute exact path="/login" component={Login} />
              <PrivateRoute exact path="/profiles" component={Dashboard} />
              <PrivateRoute path="/profiles/new" component={ProfileNew} />
              <PrivateRoute
                exact
                path="/profiles/edit/:id"
                component={ProfileEdit}
              />
              <Route exact path="/profiles/card/:id" component={ProfileCard} />
            </div>
          </Router>
        </ChakraProvider>
      </div>
    );
  }
}

export default connect(null, actions)(App);
