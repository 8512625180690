export const FETCH_USER = "fetch_user";
export const FETCH_PROFILES = "fetch_profiles";
export const FETCH_PROFILE = "fetch_profile";
export const DELETE_PROFILE = "delete_profile";
export const EDIT_PROFILE = "edit_profile";
export const FETCH_LOGO = "fetch_logo";
export const FETCH_AVATAR = "fetch_avatar";
export const GET_CARD = "GET_CARD";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const ADD_PROFILE = "add_profile";
