const getMessageFromErrorCode = (errorCode) => {
  switch (errorCode) {
    case "auth/wrong-password":
      return "Password is not corrected";
      break;
    case "auth/user-not-found":
      return "User/Email is not found";
      break;
    case "auth/too-many-requests":
      return "Too many invalid login attemts";
      break;
    case "auth/invalid-photo-url":
      return "Image is required";
      break;
    case "auth/invalid-email":
      return "Invalid email";
      break;
    case "auth/email-already-exists":
      return "Email already exists";
      break;
    case "auth/invalid-login-credentials":
      return "Email is not found or Password is not corrected ";
      break;
    default:
      return "Login failed. Please try again.";
      break;
  }
};

export { getMessageFromErrorCode };
