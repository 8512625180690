import img1 from "../image/home/davidcard-standard-design1.png";
import img2 from "../image/home/davidcard-standard-design2.png";
import img3 from "../image/home/davidcard-custom-design1.png";
import img4 from "../image/home/davidcard-custom-design2.png";

const imgalt = "David card นามบัตร nfc ดีไซน์ต่างๆ";

function CardDesign() {
  return (
    <div
      className="section-containter-carddesign"
      style={{ background: "#e9edff", minHeight: "800px" }}
    >
      {/*  section 1 */}
      <div>
        <div className="grid-item-title">Standard Design 1 </div>
        <div className="grid-item" style={{ flexDirection: "column" }}>
          <hr
            style={{
              width: "20%",
              borderTop: "4px solid #2d46b9",
              borderRadius: "5px",
            }}
          />
          <br />
          <img src={img1} alt={imgalt} style={{ margin: "5em" }} />
        </div>
      </div>

      <div>
        <div className="grid-item-title">Standard Design 2 </div>
        <div className="grid-item" style={{ flexDirection: "column" }}>
          <hr
            style={{
              width: "20%",
              borderTop: "4px solid #2d46b9",
              borderRadius: "5px",
            }}
          />
          <br />
          <img src={img2} alt={imgalt} style={{ margin: "5em" }} />
        </div>
      </div>
      {/*  section 2 */}

      <div>
        <div className="grid-item-title">Custom Design </div>

        <div className="grid-item" style={{ flexDirection: "column" }}>
          <hr
            style={{
              width: "20%",
              borderTop: "4px solid #2d46b9",
              borderRadius: "5px",
            }}
          />
          <br />
          <img src={img3} alt={imgalt} style={{ margin: "5em" }} />
        </div>
      </div>

      <div>
        <div className="grid-item-title">Digital Amazing Card</div>
        <div className="grid-item" style={{ flexDirection: "column" }}>
          <hr
            style={{
              width: "20%",
              borderTop: "4px solid #2d46b9",
              borderRadius: "5px",
            }}
          />
          <br />
          <img src={img4} alt={imgalt} style={{ margin: "5em" }} />
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
    </div>
  );
}

export default CardDesign;
