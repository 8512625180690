import { ThemeContext } from '@emotion/react'
import QRCode from 'qrcode'
import {useEffect, useState} from 'react'
import {Image} from '@chakra-ui/react'

const GenQRCode = ({text}) => {
    const [src,setSrc] = useState('')

    useEffect(() => {
        QRCode.toDataURL(text).then(setSrc)
    },[])

    return (
        <>
            <Image src={src} w='150px' h='150px'/>
        </>
    )
}

export default GenQRCode