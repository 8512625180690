function Footer() {
  const info = {
    name: "คุณปรียานันท์ เจริญรัตน์",
    position: "Head of Sale",
    tel: "0619942596",
    email: "davidbranding77@gmail.com",
    address: "222/7 Soi Sukumvit 24 , Meaung Rayong , Thailand , 21000",
    website: "http://www.davidcard.co",

  };

  return (
    <div className="section-containter" style={{minHeight:"400px"}}>
        <div className="grid-item" >
        <iframe
        style={{width:"100%",height:"100%"}}
          src={
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3892.4993499584584!2d101.24817751526737!3d12.680797491047151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3102fbced50e6401%3A0x96cf797342613aa4!2zVGhlIEdhcmRlbm8gQ2FmZSBieSBJeWFyYSAoUmF5b25nKSDguYDguJTguK3guLAg4LiB4Liy4Lij4LmM4LmA4LiU4LiZ4LmC4LiZIOC4hOC4suC5gOC4n-C5iCDguJrguLLguKIg4LmE4Lit4Lii4Lij4LiyIOC4o-C4sOC4ouC4reC4hw!5e0!3m2!1sth!2sth!4v1665210189071!5m2!1sth!2sth"
          }
        />
        </div>

        <div className="grid-item" style={{flexDirection:"column"}}>
          <div style={{width:"100%"}}>

            <h4 className="footer-title">ติดต่อเรา
            </h4>

            <ul className="footer-li">
              <li>โทร : <a href={"tel:" + info["tel"]}>{info["tel"]}</a></li>
              <li>Email : <a href={"mailto:" + info["email"]}>{info["email"]}</a></li>
              <li>Website : <a href={info["website"]}>{info["website"]}</a></li>
            </ul>
          </div>
          
          <br />
          <br />
          <div style={{width:"100%"}}>
            <h4 className="footer-title" style={{width:"100%"}}>ที่อยู่</h4>
            <ul className="footer-li">
              <li>{info["address"]}</li>
            </ul>
          </div>
          <br />
          <br />
          <div style={{width:"100%"}}>
            <p className="footer-copyright">
              @copyright David Branding . All Right Reserved .{" "}
            </p>
          </div>
        </div>

    </div>
  );
}

export default Footer;
