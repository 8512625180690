import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfile } from "../../actions";
import {
  Container,
  Box,
  Text,
  Image,
  Flex,
  Center,
  SimpleGrid,
  Link,
  Button,
} from "@chakra-ui/react";

// import CoverImage from "../../image/cover.png";
// import logo from "../../image/unnamed.gif";
// import QRCode from "qrcode.react";
import bgimage from "../../image/view.png";
import GenQRCode from "./GenQRCode.js";
import noImage from "../../image/noimage.png";
import blankUser from "../../image/blankuser.png";
import "./ProfileCard.css";
import { ref, getDownloadURL } from "firebase/storage";
import { getDocs, collectionGroup, query, where } from "firebase/firestore";
import { storage, firestore } from "../../firebase";
import VCard from "vcard-creator";

class ProfileCard extends Component {
  constructor() {
    super();
    this.state = {
      logo64: "",
      avatar64: "",
      profile: {},
    };
  }

  async componentDidMount() {
    const queryRef = query(
      collectionGroup(firestore, "profiles"),
      where("_id", "==", this.props.match.params.id)
    );
    const querySnapshot = await getDocs(queryRef);
    querySnapshot.forEach((doc) => {
      this.setState({ profile: doc.data() });
    });

    getDownloadURL(
      ref(storage, `logos/thumb_${this.props.match.params.id}`)
    ).then(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          logo64: reader.result,
        });
      };
      reader.readAsDataURL(blob);
    });
    getDownloadURL(
      ref(storage, `avatars/thumb_${this.props.match.params.id}`)
    ).then(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          avatar64: reader.result,
        });
      };
      reader.readAsDataURL(blob);
    });
  }

  generateVcfFile = () => {
    const myVCard = new VCard();

    myVCard.addName(this.state.profile.input_name);

    this.state.profile.company_name !== "-" &&
      myVCard.addCompany(this.state.profile.company_name);

    this.state.profile.title !== "-" &&
      myVCard.addJobtitle(this.state.profile.title);

    this.state.profile.aboutme !== "-" &&
      myVCard.addNote(this.state.profile.aboutme);

    this.state.profile.address !== "-" &&
      myVCard.addAddress(this.state.profile.address);

    this.state.profile.tel1 !== "-" &&
      myVCard.addPhoneNumber(this.state.profile.tel1);

    this.state.profile.tel2 !== "-" &&
      myVCard.addPhoneNumber(this.state.profile.tel2);

    this.state.profile.tel3 !== "-" &&
      myVCard.addPhoneNumber(this.state.profile.tel3);

    this.state.profile.email1 !== "-" &&
      myVCard.addEmail(this.state.profile.email1);

    this.state.profile.email2 !== "-" &&
      myVCard.addEmail(this.state.profile.email2);

    this.state.profile.website !== "-" &&
      myVCard.addURL(this.state.profile.website);

    myVCard.addLogo(
      this.state.logo64.replace(/^data:image\/[a-z]+;base64,/, ""),
      "JPEG"
    );

    myVCard.addPhoto(
      this.state.avatar64.replace(/^data:image\/[a-z]+;base64,/, ""),
      "JPEG"
    );

    this.state.profile.whatsapp !== "-" &&
      myVCard.addSocial(
        `http://line.me/ti/p/~${this.state.profile.line}`,
        "Line"
      );

    this.state.profile.whatsapp !== "-" &&
      myVCard.addSocial(
        `https://wa.me/${this.state.profile.whatsapp}`,
        "WhatsApp"
      );

    this.state.profile.facebook !== "-" &&
      myVCard.addSocial(this.state.profile.facebook, "Facebook");

    this.state.profile.instagram !== "-" &&
      myVCard.addSocial(
        `https://www.instagram.com/${this.state.profile.instagram}`,
        "Instagram"
      );

    this.state.profile.tiktok !== "-" &&
      myVCard.addSocial(
        `https://tiktok.com/@${this.state.profile.tiktok}`,
        "Tiktok"
      );

    this.state.profile.linkedIn !== "-" &&
      myVCard.addSocial(this.state.profile.linkedIn, "LinkedIn");

    let blob = new Blob([myVCard], {
      type: "text/vcard",
    });
    let url = URL.createObjectURL(blob);
    const fakeLink = window.document.createElement("a");
    fakeLink.download = `${this.state.profile.input_name}.vcf`;
    fakeLink.textContent = `${this.state.profile.input_name}`;
    fakeLink.href = url;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  render() {
    if (!this.state.profile) {
      return <div>Loading...</div>;
    }
    const link = window.location.href;

    return (
      <>
        <Container p="0" maxWidth={"full"} className="bg_profilecard">
          <Flex justifyContent={"center"}>
            <Box
              // bg="#FFFFFF"
              maxW={"600px"}
              minW={"350px"}
              minH="980px"
              borderRadius={"20px"}
              pb={{ base: "40", sm: "40", md: "10" }}
              className="bg_profilecard_small"
            >
              <Flex alignItems={"center"} direction="column" gap="10px">
                <Link
                  href={this.state.profile.website}
                  target={"_blank"}
                  alignSelf={"flex-start"}
                  ml="30"
                  mt="10"
                >
                  <Image
                    src={this.state.logo64 ? this.state.logo64 : noImage}
                    cursor={
                      this.state.profile.website !== "-" ? "default" : "none"
                    }
                    borderRadius="50%"
                    boxSize={"80px"}
                    p="5px"
                    mr="80%"
                    mt="10px"
                  ></Image>
                </Link>
                <Image
                  src={this.state.avatar64 ? this.state.avatar64 : blankUser}
                  borderRadius="50%"
                  boxSize="230px"
                  boxShadow="4px 4px 4px rgba(0, 0, 0, 0.35)"
                  mb="20px"
                />
                <Text
                  width={"200px"}
                  fontSize="24px"
                  textAlign={"center"}
                  fontWeight={"600"}
                  textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  maxW="200px"
                  color="#003257"
                  m="10px"
                >
                  {this.state.profile.input_name_en} <br />
                  {this.state.profile.input_name}
                </Text>
                <Center
                  color="white"
                  width="300px"
                  bg="#0094FF"
                  borderRadius={"20px"}
                  fontSize="18px"
                  textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  wordBreak={"break-word"}
                  p="5px"
                  mb="10px"
                >
                  {this.state.profile.title_en} <br />
                  {this.state.profile.title}
                </Center>
                <Center
                  bg="white"
                  minH="100px"
                  maxH="400px"
                  width={"300px"}
                  borderRadius={"20px"}
                  p="5px"
                  mb="10px"
                >
                  <Text
                    textAlign={"center"}
                    fontWeight="600"
                    wordBreak="break-word"
                    pr="10"
                    pl="10"
                  >
                    {this.state.profile.aboutme}
                  </Text>
                </Center>
              </Flex>
              <Flex pt="10"></Flex>
              <Box
                p="10"
                bg="#FFFFFF"
                minH="300px"
                fontWeight="400"
                fontSize={"18px"}
                opacity="0.9"
                padding={"20px"}
              >
                <SimpleGrid
                  gridTemplateColumns={["minmax(0,1fr) minmax(0,1fr)"]}
                >
                  {this.state.profile.tel1 !== "-" && (
                    <>
                      <Text color="#424C55" pb="20">
                        Telephone <br /> โทรศัพท์
                      </Text>
                      <Flex direction={"column"} pb="20">
                        <Text color="#0094FF" wordBreak={"break-word"}>
                          <a href={"tel:" + this.state.profile.tel1}>
                            {this.state.profile.tel1}
                          </a>
                        </Text>
                        {this.state.profile.tel2 !== "-" && (
                          <>
                            <Text color="#0094FF" wordBreak={"break-word"}>
                              <a href={"tel:" + this.state.profile.tel2}>
                                {this.state.profile.tel2}
                              </a>
                            </Text>
                          </>
                        )}
                        {this.state.profile.tel3 !== "-" && (
                          <>
                            <Text color="#0094FF" wordBreak={"break-word"}>
                              <a href={"tel:" + this.state.profile.tel3}>
                                {this.state.profile.tel3}
                              </a>
                            </Text>
                          </>
                        )}
                      </Flex>
                    </>
                  )}

                  {this.state.profile.email1 !== "-" && (
                    <>
                      <Text color="#424C55" pb="20">
                        Email <br /> อีเมล
                      </Text>
                      <Flex direction={"column"} pb="20">
                        <Text color="#0094FF" wordBreak={"break-word"}>
                          {this.state.profile.email1}
                        </Text>
                        {this.state.profile.email2 !== "-" && (
                          <>
                            <Text color="#0094FF" wordBreak={"break-word"}>
                              {this.state.profile.email2}
                            </Text>
                          </>
                        )}
                        {this.state.profile.email3 !== "-" && (
                          <>
                            <Text color="#0094FF" wordBreak={"break-word"}>
                              {this.state.profile.email3}
                            </Text>
                          </>
                        )}
                      </Flex>
                    </>
                  )}
                  {this.state.profile.address_en !== "-" && (
                    <>
                      <Text color="#424C55" pb="20">
                        Address <br /> ที่อยู่
                      </Text>
                      <Flex direction={"column"}>
                        <Text color="#0094FF" wordBreak={"break-word"}>
                          {this.state.profile.address_en}
                        </Text>
                        {this.state.profile.address !== "-" && (
                          <>
                            <Text color="#0094FF" wordBreak={"break-word"}>
                              {this.state.profile.address}
                            </Text>
                          </>
                        )}
                        {this.state.profile.location !== "-" && (
                          <>
                            <Link
                              color="#0094FF"
                              href={this.state.profile.location}
                              target={"_blank"}
                            >
                              กดเพื่อไปยัง Google Map
                            </Link>
                          </>
                        )}
                      </Flex>
                    </>
                  )}
                  {this.state.profile.website !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Website
                      </Text>

                      <Link
                        color="#0094FF"
                        href={this.state.profile.website}
                        target={"_blank"}
                      >
                        {this.state.profile.website}
                      </Link>
                    </>
                  )}
                  {this.state.profile.facebook !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Facebook
                      </Text>
                      <Link
                        color="#0094FF"
                        href={this.state.profile.facebook}
                        target={"_blank"}
                      >
                        {this.state.profile.facebook}
                      </Link>
                    </>
                  )}
                  {this.state.profile.linkedIn !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        LinkedIn
                      </Text>
                      <Link
                        color="#0094FF"
                        href={this.state.profile.linkedIn}
                        target={"_blank"}
                      >
                        {this.state.profile.linkedIn}
                      </Link>
                    </>
                  )}
                  {this.state.profile.line !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Line
                      </Text>
                      <Link
                        color="#0094FF"
                        href={"http://line.me/ti/p/~" + this.state.profile.line}
                        target={"_blank"}
                      >
                        {this.state.profile.line}
                      </Link>
                    </>
                  )}
                  {this.state.profile.instagram !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Instagram
                      </Text>
                      <Link
                        color="#0094FF"
                        href={
                          "https://www.instagram.com/" +
                          this.state.profile.instagram
                        }
                        target={"_blank"}
                      >
                        {this.state.profile.instagram}
                      </Link>
                    </>
                  )}
                  {this.state.profile.tiktok !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Tiktok
                      </Text>
                      <Link
                        color="#0094FF"
                        href={
                          "https://tiktok.com/@" + this.state.profile.tiktok
                        }
                        target={"_blank"}
                      >
                        {this.state.profile.tiktok}
                      </Link>
                    </>
                  )}
                  {this.state.profile.whatsapp !== "-" && (
                    <>
                      <Text color="#424C55" wordBreak={"break-word"} pb="10">
                        Whatsapp
                      </Text>
                      <Link
                        color="#0094FF"
                        href={"https://wa.me/" + this.state.profile.whatsapp}
                        target={"_blank"}
                      >
                        {this.state.profile.whatsapp}
                      </Link>
                    </>
                  )}
                </SimpleGrid>
              </Box>
              <Flex justifyContent={"center"} mt="10">
                <GenQRCode text={link} />
              </Flex>
            </Box>
          </Flex>
        </Container>
        <Flex justifyContent={{ base: "center", sm: "center", md: "flex-end" }}>
          <Button
            as="a"
            position={"fixed"}
            bottom="0"
            bg="blue.500"
            p="8"
            color="white"
            mr={{ base: "", sm: "", md: "10", lg: "10" }}
            mb={{ base: "10" }}
            target={"_blank"}
            onClick={this.generateVcfFile}
          >
            Save contact
          </Button>
        </Flex>
      </>
    );
  }
}

const mapStateToProps = ({ profiles }, props) => {
  console.log(
    profiles.find((profile) => profile._id === props.match.params.id)
  );
  return {
    profiles,
  };
};

// export default ProfileCard;
export default connect(mapStateToProps, {
  fetchProfile,
})(ProfileCard);
